/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { RiArrowRightSLine, RiCheckLine, RiFileUserLine, RiCalendarCheckLine, RiTeamLine, RiUser3Line, RiShieldCheckLine, RiFileTextLine, RiTimeLine, RiBriefcaseLine } from "react-icons/ri"

const EmployeeOnboardingGuidePage = () => {
  const onboardingTemplates = [
    {
      id: "welcome-kit",
      title: "New Employee Welcome Kit",
      description: "Comprehensive package of orientation materials, company information, and first-day essentials.",
      link: "/resources/templates/welcome-kit-template"
    },
    {
      id: "onboarding-checklist",
      title: "Onboarding Process Checklist",
      description: "Structured timeline of tasks for HR, managers, and new hires during the onboarding process.",
      link: "/resources/templates/onboarding-checklist-template"
    },
    {
      id: "training-plan",
      title: "Role-Specific Training Plan",
      description: "Customizable template for creating structured learning paths for different roles.",
      link: "/resources/templates/training-plan-template"
    },
    {
      id: "onboarding-feedback",
      title: "Onboarding Experience Survey",
      description: "Feedback form to evaluate and improve the onboarding process based on new hire experiences.",
      link: "/resources/templates/onboarding-survey-template"
    }
  ]

  return (
    <Layout>
      <SEO 
        title="Employee Onboarding Guide | Best Practices for New Hire Success"
        description="Comprehensive guide to effective employee onboarding. Learn how to create an onboarding program that engages new hires and sets them up for success."
        keywords={[
          "employee onboarding best practices",
          "new hire orientation guide",
          "onboarding process template",
          "digital onboarding",
          "remote employee onboarding",
          "employee onboarding checklist",
          "first 90 days plan",
          "onboarding automation"
        ]}
      />
      
      {/* Hero Section */}
      <div sx={{
        background: 'linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)',
        py: [4, 5],
        position: 'relative'
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: [3, 4]
        }}>
          <div sx={{
            textAlign: 'center',
            maxWidth: '800px',
            mx: 'auto'
          }}>
            <h1 sx={{
              fontSize: ['1.8rem', '2.2rem', '2.5rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary'
            }}>
              The Complete Guide to Employee Onboarding
            </h1>
            
            <p sx={{
              fontSize: ['1rem', '1.1rem'],
              lineHeight: 1.5,
              mb: 4,
              color: 'text',
              opacity: 0.9
            }}>
              Create an outstanding onboarding experience that engages new hires 
              and sets them up for long-term success and productivity.
            </p>
            
            <div sx={{
              display: 'flex',
              gap: [2, 3],
              justifyContent: 'center',
              flexWrap: 'wrap'
            }}>
              <a href="#templates" sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                bg: 'primary',
                color: 'white',
                py: 2,
                px: 3,
                borderRadius: '6px',
                fontSize: '0.95rem',
                fontWeight: 600,
                textDecoration: 'none',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 6px 15px rgba(0,0,0,0.15)'
                }
              }}>
                Get Templates <RiFileTextLine />
              </a>
              
              <Link to="/tools/turnover-cost-calculator" sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                bg: 'white',
                color: 'primary',
                py: 2,
                px: 3,
                borderRadius: '6px',
                fontSize: '0.95rem',
                fontWeight: 600,
                textDecoration: 'none',
                border: '2px solid',
                borderColor: 'primary',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                boxShadow: '0 4px 10px rgba(0,0,0,0.05)',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                }
              }}>
                Try Our Turnover Calculator <RiTimeLine />
              </Link>
            </div>
          </div>
        </div>
      </div>
      
      {/* Main Content */}
      <div sx={{
        bg: 'white',
        py: [4, 5],
        position: 'relative'
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: [3, 4],
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr', '2fr 1fr'],
          gap: [4, 5]
        }}>
          {/* Left Column - Main Content */}
          <div>
            {/* Introduction */}
            <div sx={{ 
              mb: 4,
              pb: 4,
              borderBottom: '1px solid',
              borderColor: 'rgba(0,0,0,0.06)'
            }}>
              <h2 sx={{
                fontSize: '1.75rem',
                fontWeight: 700,
                color: 'text',
                mb: 3
              }}>
                Why Effective Onboarding Matters
              </h2>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6,
                mb: 3
              }}>
                Employee onboarding is much more than paperwork and introductions. It's your organization's opportunity 
                to make a powerful first impression, integrate new hires into your culture, and build the foundation for 
                high engagement and retention. A strategic onboarding process ensures new employees quickly become productive 
                members of your team.
              </p>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6,
                mb: 3
              }}>
                Research shows that effective onboarding can improve employee retention by 82% and productivity by over 70%. 
                Organizations with strong onboarding programs see significantly higher employee satisfaction and faster time-to-productivity 
                compared to those with minimal or unstructured onboarding.
              </p>
            </div>
            
            {/* Key Elements of Onboarding */}
            <div sx={{ 
              mb: 4,
              pb: 4,
              borderBottom: '1px solid',
              borderColor: 'rgba(0,0,0,0.06)'
            }}>
              <h2 sx={{
                fontSize: ['1.5rem', '1.75rem'],
                fontWeight: 700,
                color: 'text',
                mb: 3
              }}>
                The Four Phases of Effective Onboarding
              </h2>
              
              <div sx={{
                display: 'grid',
                gridTemplateColumns: ['1fr', null, 'repeat(2, 1fr)'],
                gap: 4,
                mb: 4
              }}>
                {/* Pre-Boarding */}
                <div sx={{
                  p: 3,
                  borderRadius: '8px',
                  boxShadow: '0 2px 15px rgba(0,0,0,0.06)',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  '&:hover': {
                    transform: 'translateY(-3px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                  }
                }}>
                  <div sx={{
                    color: 'primary',
                    fontSize: '1.5rem',
                    mb: 2
                  }}>
                    <RiCalendarCheckLine />
                  </div>
                  
                  <h3 sx={{
                    fontSize: '1.2rem',
                    fontWeight: 700,
                    mb: 2,
                    color: 'text'
                  }}>
                    Pre-Boarding
                  </h3>
                  
                  <p sx={{
                    fontSize: '0.95rem',
                    mb: 3,
                    lineHeight: 1.5,
                    color: 'text'
                  }}>
                    Engage new hires between offer acceptance and day one to build excitement and prepare them for success.
                  </p>
                  
                  <ul sx={{
                    listStyle: 'none',
                    p: 0,
                    m: 0,
                    fontSize: '0.9rem'
                  }}>
                    {[
                      "Welcome communications",
                      "Pre-arrival paperwork",
                      "Technology setup",
                      "Team introductions",
                      "Pre-reading materials"
                    ].map((item) => (
                      <li key={item} sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        mb: 1,
                        color: 'text'
                      }}>
                        <span sx={{ color: 'primary', mr: 2, mt: '3px', flexShrink: 0 }}><RiCheckLine size={14} /></span>
                        <span>{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                
                {/* First Week */}
                <div sx={{
                  p: 3,
                  borderRadius: '8px',
                  boxShadow: '0 2px 15px rgba(0,0,0,0.06)',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  '&:hover': {
                    transform: 'translateY(-3px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                  }
                }}>
                  <div sx={{
                    color: 'primary',
                    fontSize: '1.5rem',
                    mb: 2
                  }}>
                    <RiUser3Line />
                  </div>
                  
                  <h3 sx={{
                    fontSize: '1.2rem',
                    fontWeight: 700,
                    mb: 2,
                    color: 'text'
                  }}>
                    First Week
                  </h3>
                  
                  <p sx={{
                    fontSize: '0.95rem',
                    mb: 3,
                    lineHeight: 1.5,
                    color: 'text'
                  }}>
                    Create a structured first week that welcomes new hires, provides essential information, and starts building connections.
                  </p>
                  
                  <ul sx={{
                    listStyle: 'none',
                    p: 0,
                    m: 0,
                    fontSize: '0.9rem'
                  }}>
                    {[
                      "Warm welcome experience",
                      "Workspace setup",
                      "Company overview",
                      "Essential trainings",
                      "Early wins and tasks"
                    ].map((item) => (
                      <li key={item} sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mb: 1,
                        color: 'text'
                      }}>
                        <span sx={{ color: 'primary', mr: 2 }}><RiCheckLine size={14} /></span>
                        <span>{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                
                {/* First Month */}
                <div sx={{
                  p: 3,
                  borderRadius: '8px',
                  boxShadow: '0 2px 15px rgba(0,0,0,0.06)',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  '&:hover': {
                    transform: 'translateY(-3px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                  }
                }}>
                  <div sx={{
                    color: 'primary',
                    fontSize: '1.5rem',
                    mb: 2
                  }}>
                    <RiTeamLine />
                  </div>
                  
                  <h3 sx={{
                    fontSize: '1.2rem',
                    fontWeight: 700,
                    mb: 2,
                    color: 'text'
                  }}>
                    First Month
                  </h3>
                  
                  <p sx={{
                    fontSize: '0.95rem',
                    mb: 3,
                    lineHeight: 1.5,
                    color: 'text'
                  }}>
                    Focus on role-specific training, team integration, and establishing clear expectations and goals.
                  </p>
                  
                  <ul sx={{
                    listStyle: 'none',
                    p: 0,
                    m: 0,
                    fontSize: '0.9rem'
                  }}>
                    {[
                      "Role-specific training",
                      "Team integration",
                      "Key stakeholder meetings",
                      "Performance expectations",
                      "Regular feedback sessions"
                    ].map((item) => (
                      <li key={item} sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mb: 1,
                        color: 'text'
                      }}>
                        <span sx={{ color: 'primary', mr: 2 }}><RiCheckLine size={14} /></span>
                        <span>{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                
                {/* First 90 Days */}
                <div sx={{
                  p: 3,
                  borderRadius: '8px',
                  boxShadow: '0 2px 15px rgba(0,0,0,0.06)',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  '&:hover': {
                    transform: 'translateY(-3px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                  }
                }}>
                  <div sx={{
                    color: 'primary',
                    fontSize: '1.5rem',
                    mb: 2
                  }}>
                    <RiShieldCheckLine />
                  </div>
                  
                  <h3 sx={{
                    fontSize: '1.2rem',
                    fontWeight: 700,
                    mb: 2,
                    color: 'text'
                  }}>
                    First 90 Days
                  </h3>
                  
                  <p sx={{
                    fontSize: '0.95rem',
                    mb: 3,
                    lineHeight: 1.5,
                    color: 'text'
                  }}>
                    Complete the transition to full productivity and ensure new hires are fully integrated into the organization.
                  </p>
                  
                  <ul sx={{
                    listStyle: 'none',
                    p: 0,
                    m: 0,
                    fontSize: '0.9rem'
                  }}>
                    {[
                      "Career development planning",
                      "Performance check-ins",
                      "Cross-functional exposure",
                      "Ongoing training",
                      "Onboarding evaluation"
                    ].map((item) => (
                      <li key={item} sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mb: 1,
                        color: 'text'
                      }}>
                        <span sx={{ color: 'primary', mr: 2 }}><RiCheckLine size={14} /></span>
                        <span>{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            
            {/* Digital Onboarding Section */}
            <div sx={{ 
              mb: 4,
              pb: 4,
              borderBottom: '1px solid',
              borderColor: 'rgba(0,0,0,0.06)'
            }}>
              <h2 sx={{
                fontSize: ['1.5rem', '1.75rem'],
                fontWeight: 700,
                color: 'text',
                mb: 3
              }}>
                Digital & Remote Onboarding Best Practices
              </h2>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6,
                mb: 4
              }}>
                In today's hybrid and remote work environments, creating an effective digital onboarding experience 
                is essential. Here are key strategies to ensure your virtual onboarding is just as engaging and effective 
                as in-person experiences.
              </p>
              
              <div sx={{
                display: 'grid',
                gridTemplateColumns: ['1fr', null, 'repeat(2, 1fr)'],
                gap: 4,
                mb: 4
              }}>
                {/* Technology & Access */}
                <div sx={{
                  p: 3,
                  borderRadius: '8px',
                  boxShadow: '0 2px 15px rgba(0,0,0,0.06)',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  '&:hover': {
                    transform: 'translateY(-3px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                  }
                }}>
                  <div sx={{
                    color: 'primary',
                    fontSize: '1.5rem',
                    mb: 2
                  }}>
                    <RiBriefcaseLine />
                  </div>
                  
                  <h3 sx={{
                    fontSize: '1.2rem',
                    fontWeight: 700,
                    mb: 2,
                    color: 'text'
                  }}>
                    Technology & Access
                  </h3>
                  
                  <p sx={{
                    fontSize: '0.95rem',
                    mb: 3,
                    lineHeight: 1.5,
                    color: 'text'
                  }}>
                    Ensure new hires have the tools, technology, and access they need from day one to be productive.
                  </p>
                  
                  <ul sx={{
                    listStyle: 'none',
                    p: 0,
                    m: 0,
                    fontSize: '0.9rem'
                  }}>
                    {[
                      "Ship equipment early with setup instructions",
                      "Create a digital welcome kit with all essential resources",
                      "Ensure all software and access permissions are ready before day one",
                      "Provide IT support contacts and troubleshooting resources",
                      "Use a digital onboarding platform to track progress"
                    ].map((item) => (
                      <li key={item} sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        mb: 1,
                        color: 'text'
                      }}>
                        <span sx={{ color: 'primary', mr: 2, mt: '3px', flexShrink: 0 }}><RiCheckLine size={14} /></span>
                        <span>{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                
                {/* Connection & Culture */}
                <div sx={{
                  p: 3,
                  borderRadius: '8px',
                  boxShadow: '0 2px 15px rgba(0,0,0,0.06)',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  '&:hover': {
                    transform: 'translateY(-3px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                  }
                }}>
                  <div sx={{
                    color: 'primary',
                    fontSize: '1.5rem',
                    mb: 2
                  }}>
                    <RiTeamLine />
                  </div>
                  
                  <h3 sx={{
                    fontSize: '1.2rem',
                    fontWeight: 700,
                    mb: 2,
                    color: 'text'
                  }}>
                    Connection & Culture
                  </h3>
                  
                  <p sx={{
                    fontSize: '0.95rem',
                    mb: 3,
                    lineHeight: 1.5,
                    color: 'text'
                  }}>
                    Build meaningful connections and transmit company culture even in a remote environment.
                  </p>
                  
                  <ul sx={{
                    listStyle: 'none',
                    p: 0,
                    m: 0,
                    fontSize: '0.9rem'
                  }}>
                    {[
                      "Schedule virtual coffee chats with team members and key stakeholders",
                      "Assign an onboarding buddy for daily check-ins",
                      "Create digital spaces for informal interaction",
                      "Host virtual team building activities",
                      "Share culture videos and company stories"
                    ].map((item) => (
                      <li key={item} sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        mb: 1,
                        color: 'text'
                      }}>
                        <span sx={{ color: 'primary', mr: 2, mt: '3px', flexShrink: 0 }}><RiCheckLine size={14} /></span>
                        <span>{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                
                {/* Communication & Engagement */}
                <div sx={{
                  p: 3,
                  borderRadius: '8px',
                  boxShadow: '0 2px 15px rgba(0,0,0,0.06)',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  '&:hover': {
                    transform: 'translateY(-3px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                  }
                }}>
                  <div sx={{
                    color: 'primary',
                    fontSize: '1.5rem',
                    mb: 2
                  }}>
                    <RiUser3Line />
                  </div>
                  
                  <h3 sx={{
                    fontSize: '1.2rem',
                    fontWeight: 700,
                    mb: 2,
                    color: 'text'
                  }}>
                    Communication & Engagement
                  </h3>
                  
                  <p sx={{
                    fontSize: '0.95rem',
                    mb: 3,
                    lineHeight: 1.5,
                    color: 'text'
                  }}>
                    Maintain clear communication channels and keep new hires engaged throughout the process.
                  </p>
                  
                  <ul sx={{
                    listStyle: 'none',
                    p: 0,
                    m: 0,
                    fontSize: '0.9rem'
                  }}>
                    {[
                      "Over-communicate compared to in-person onboarding",
                      "Use multiple communication channels (video, chat, email)",
                      "Break training into digestible microlearning segments",
                      "Incorporate interactive elements like quizzes and polls",
                      "Gather regular feedback to address remote-specific challenges"
                    ].map((item) => (
                      <li key={item} sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        mb: 1,
                        color: 'text'
                      }}>
                        <span sx={{ color: 'primary', mr: 2, mt: '3px', flexShrink: 0 }}><RiCheckLine size={14} /></span>
                        <span>{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                
                {/* Structure & Support */}
                <div sx={{
                  p: 3,
                  borderRadius: '8px',
                  boxShadow: '0 2px 15px rgba(0,0,0,0.06)',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  '&:hover': {
                    transform: 'translateY(-3px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                  }
                }}>
                  <div sx={{
                    color: 'primary',
                    fontSize: '1.5rem',
                    mb: 2
                  }}>
                    <RiShieldCheckLine />
                  </div>
                  
                  <h3 sx={{
                    fontSize: '1.2rem',
                    fontWeight: 700,
                    mb: 2,
                    color: 'text'
                  }}>
                    Structure & Support
                  </h3>
                  
                  <p sx={{
                    fontSize: '0.95rem',
                    mb: 3,
                    lineHeight: 1.5,
                    color: 'text'
                  }}>
                    Provide clear structure and readily available support to help new hires navigate their onboarding journey.
                  </p>
                  
                  <ul sx={{
                    listStyle: 'none',
                    p: 0,
                    m: 0,
                    fontSize: '0.9rem'
                  }}>
                    {[
                      "Create clear daily and weekly schedules for the first month",
                      "Establish specific virtual office hours for questions",
                      "Provide access to mental health and wellbeing resources",
                      "Set up regular manager check-ins with structured agendas",
                      "Create a digital FAQ resource that grows over time"
                    ].map((item) => (
                      <li key={item} sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        mb: 1,
                        color: 'text'
                      }}>
                        <span sx={{ color: 'primary', mr: 2, mt: '3px', flexShrink: 0 }}><RiCheckLine size={14} /></span>
                        <span>{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            
            {/* Templates Section */}
            <div id="templates" sx={{ 
              mb: 4,
              pb: 4,
              borderBottom: '1px solid',
              borderColor: 'rgba(0,0,0,0.06)'
            }}>
              <h2 sx={{
                fontSize: ['1.5rem', '1.75rem'],
                fontWeight: 700,
                color: 'text',
                mb: 3
              }}>
                Onboarding Templates & Resources
              </h2>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6,
                mb: 4
              }}>
                Use these templates to create a consistent, comprehensive onboarding experience for every new hire.
              </p>
              
              <div sx={{
                display: 'grid',
                gridTemplateColumns: ['1fr', '1fr', 'repeat(2, 1fr)'],
                gap: 4,
                mb: 4
              }}>
                {onboardingTemplates.map((template) => (
                  <div key={template.id} sx={{
                    p: 3,
                    borderRadius: '8px',
                    boxShadow: '0 2px 15px rgba(0,0,0,0.06)',
                    transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                    '&:hover': {
                      transform: 'translateY(-3px)',
                      boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                    }
                  }}>
                    <h3 sx={{
                      fontSize: '1.2rem',
                      fontWeight: 700,
                      mb: 2,
                      color: 'text'
                    }}>
                      {template.title}
                    </h3>
                    
                    <p sx={{
                      fontSize: '0.95rem',
                      lineHeight: 1.5,
                      mb: 3,
                      color: 'text'
                    }}>
                      {template.description}
                    </p>
                    
                    <Link to={template.link} sx={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      gap: 1,
                      color: 'primary',
                      fontWeight: 600,
                      fontSize: '0.95rem',
                      textDecoration: 'none',
                      '&:hover': {
                        textDecoration: 'underline'
                      }
                    }}>
                      Download Template <RiArrowRightSLine />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            
            {/* Related Resources Section */}
            <div sx={{
              mb: 4,
              borderRadius: '8px',
              p: [3, 4],
              boxShadow: '0 2px 15px rgba(0,0,0,0.06)'
            }}>
              <h2 sx={{
                fontSize: ['1.5rem', '1.75rem'],
                fontWeight: 700,
                mb: 3,
                color: 'text'
              }}>
                Related Resources
              </h2>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6,
                mb: 4
              }}>
                Explore these additional guides and tools to build a comprehensive employee lifecycle strategy.
              </p>
              
              <div sx={{
                display: 'grid',
                gridTemplateColumns: ['1fr', '1fr', 'repeat(3, 1fr)'],
                gap: 4,
                mb: 4
              }}>
                <Link to="/guides/recruitment-hiring" sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  textDecoration: 'none',
                  color: 'text',
                  p: 3,
                  borderRadius: '8px',
                  boxShadow: '0 2px 15px rgba(0,0,0,0.06)',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  '&:hover': {
                    transform: 'translateY(-3px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                  }
                }}>
                  <span sx={{ fontWeight: 600, color: 'primary', fontSize: '1.1rem' }}>Recruitment & Hiring Guide</span>
                  <span sx={{ fontSize: '0.95rem', lineHeight: 1.5 }}>Best practices for finding and hiring the right talent for your organization.</span>
                </Link>
                
                <Link to="/guides/employee-engagement" sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  textDecoration: 'none',
                  color: 'text',
                  p: 3,
                  borderRadius: '8px',
                  boxShadow: '0 2px 15px rgba(0,0,0,0.06)',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  '&:hover': {
                    transform: 'translateY(-3px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                  }
                }}>
                  <span sx={{ fontWeight: 600, color: 'primary', fontSize: '1.1rem' }}>Employee Engagement Guide</span>
                  <span sx={{ fontSize: '0.95rem', lineHeight: 1.5 }}>Strategies for building a highly engaged and productive workforce.</span>
                </Link>
                
                <Link to="/tools/turnover-cost-calculator" sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  textDecoration: 'none',
                  color: 'text',
                  p: 3,
                  borderRadius: '8px',
                  boxShadow: '0 2px 15px rgba(0,0,0,0.06)',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  '&:hover': {
                    transform: 'translateY(-3px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                  }
                }}>
                  <span sx={{ fontWeight: 600, color: 'primary', fontSize: '1.1rem' }}>Turnover Cost Calculator</span>
                  <span sx={{ fontSize: '0.95rem', lineHeight: 1.5 }}>Calculate the true cost of employee turnover and the ROI of improved onboarding.</span>
                </Link>
              </div>
            </div>
          </div>
          
          {/* Right Column - Sidebar */}
          <div>
            {/* What You'll Learn Box */}
            <div sx={{
              p: [3, 4],
              mb: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 15px rgba(0,0,0,0.06)',
              background: 'linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)',
            }}>
              <h3 sx={{
                fontSize: ['1.1rem', '1.2rem'],
                fontWeight: 700,
                mb: 3,
                color: 'primary'
              }}>
                What You'll Learn
              </h3>
              
              <ul sx={{
                listStyle: 'none',
                p: 0,
                m: 0
              }}>
                {[
                  "How to design a comprehensive onboarding program",
                  "Creating personalized experiences for different roles",
                  "Digital and remote onboarding best practices",
                  "Building connections and culture integration",
                  "Measuring onboarding effectiveness",
                  "Setting up 30-60-90 day plans",
                  "Common onboarding mistakes to avoid",
                  "Onboarding automation and technology"
                ].map((item) => (
                  <li key={item} sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    mb: 2,
                    color: 'text'
                  }}>
                    <span sx={{ color: 'primary', mr: 2, mt: '4px', flexShrink: 0 }}><RiCheckLine /></span>
                    <span sx={{ fontSize: ['0.9rem', '1rem'] }}>{item}</span>
                  </li>
                ))}
              </ul>
            </div>
            
            {/* Need Help Box */}
            <div sx={{
              p: [3, 4],
              mb: 4,
              borderRadius: '8px',
              bg: 'primary',
              color: 'white',
              boxShadow: '0 2px 15px rgba(0,0,0,0.1)'
            }}>
              <h3 sx={{
                fontSize: ['1.1rem', '1.2rem'],
                fontWeight: 700,
                mb: 3,
                color: 'white'
              }}>
                Need Help With Your Onboarding Process?
              </h3>
              
              <p sx={{
                fontSize: ['0.9rem', '0.95rem'],
                mb: 4,
                lineHeight: 1.6
              }}>
                Our HR technology experts can help you implement digital onboarding solutions that improve employee engagement and retention.
              </p>
              
              <Link to="/contact" sx={{
                display: 'inline-block',
                bg: 'white',
                color: 'primary',
                py: 2,
                px: 3,
                borderRadius: '6px',
                fontWeight: 600,
                textDecoration: 'none',
                fontSize: '0.95rem',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                }
              }}>
                Get Expert Advice
              </Link>
            </div>
            
            {/* Key Statistics Box */}
            <div sx={{
              p: [3, 4],
              mb: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 15px rgba(0,0,0,0.06)'
            }}>
              <h3 sx={{
                fontSize: ['1.1rem', '1.2rem'],
                fontWeight: 700,
                mb: 3,
                color: 'primary'
              }}>
                Key Onboarding Statistics
              </h3>
              
              <ul sx={{
                listStyle: 'none',
                p: 0,
                m: 0
              }}>
                {[
                  "Organizations with strong onboarding improve retention by 82%",
                  "69% of employees are more likely to stay with a company for 3+ years after a great onboarding experience",
                  "New employees who complete structured onboarding are 58% more likely to remain after 3 years",
                  "Only 12% of employees strongly agree their organization does onboarding well",
                  "It takes an average of 8-12 months for a new hire to reach full productivity",
                  "20% of employee turnover happens in the first 45 days"
                ].map((item) => (
                  <li key={item} sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    mb: 2,
                    color: 'text'
                  }}>
                    <span sx={{ color: 'primary', mr: 2, mt: '4px', flexShrink: 0 }}><RiCheckLine /></span>
                    <span sx={{ fontSize: ['0.9rem', '1rem'] }}>{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default EmployeeOnboardingGuidePage 